// src/app/store.js
import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from '../services/apiSlice';
import favoritesReducer from "./favoritesSlice";

export const store = configureStore({
  reducer: {
    // Add the API slice reducer to the store
    [apiSlice.reducerPath]: apiSlice.reducer,
    favorites: favoritesReducer,

  },
  // Adding the api middleware enables caching, invalidation, polling, and other features of RTK Query
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(apiSlice.middleware),
});

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import SearchModal from "./SearchModal";
import FavoriteGames from "./FavoriteGames";
import { useLazyGetGamesByTitlesQuery } from "../services/apiSlice";
import { useSelector, useDispatch } from "react-redux";
import { loadFavorites } from "../redux/favoritesSlice";

const Header = () => {
  const dispatch = useDispatch();
  const favorites = useSelector((state) => state.favorites.favorites);
  const [isFavoritesSidebarOpen, setIsFavoritesSidebarOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const [getGames, { data: favoriteGamesData }] =
    useLazyGetGamesByTitlesQuery();

  useEffect(() => {
    dispatch(loadFavorites());
  }, [dispatch]);

  useEffect(() => {
    if (favorites.length > 0) {
      getGames({ titles: favorites });
    }
  }, [favorites, getGames]);

  const toggleFavoritesSidebar = () =>
    setIsFavoritesSidebarOpen(!isFavoritesSidebarOpen);

  return (
    <header className="text-black font-semibold text-lg">
      <div className=" mx-auto flex justify-between items-center px-5 p-4">
        <Link to="/" className="font-bold">
          <div className="md:h-[5rem] h-14 md:w-[18rem]">
            <img
              src={require("../logo-05.png")}
              alt="logo"
              className="h-full w-full"
            />
          </div>
        </Link>
        <div className="flex justify-end space-x-4">
          <button
            aria-label="Search"
            className="p-2 border border-white rounded-full shadow-md hover:bg-white transition duration-200 ease-in-out"
            onClick={() => setIsModalOpen(true)}
          >
            <svg
              className="fill-current h-6 w-6 text-black"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M10 2C5.58 2 2 5.58 2 10s3.58 8 8 8c1.85 0 3.55-.63 4.9-1.68l5.49 5.49a1 1 0 001.41-1.41l-5.49-5.49C17.37 13.55 18 11.85 18 10c0-4.42-3.58-8-8-8zm0 14c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6z" />
            </svg>
          </button>

          <button
            aria-label="FavoriteGame"
            onClick={toggleFavoritesSidebar}
            className="relative p-2 border border-white rounded-full shadow-md hover:bg-white transition duration-200 ease-in-out"
          >
            <svg
              className="fill-current h-6 w-6 text-black"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z" />
            </svg>

            {favorites.length > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {favorites.length}
              </span>
            )}
          </button>
        </div>
      </div>

      <SearchModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        games={[]}
      />
      {isFavoritesSidebarOpen && (
        <FavoriteGames
          favoriteGames={favoriteGamesData?.data || []}
          isFavoritesSidebarOpen={isFavoritesSidebarOpen}
          toggleFavoritesSidebar={toggleFavoritesSidebar}
        />
      )}
      {isFavoritesSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40"
          onClick={toggleFavoritesSidebar}
        />
      )}
    </header>
  );
};

export default Header;

import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonLoader = () => {
  return (
    <div className="px-10 py-6">
      <div className=" overflow-hidden">
        <main className="relative">
          <div className="flex gap-4 flex-col xl:flex-row">
            <div className="bg-slate-300 w-[200px]  h-[200px] hidden xl:block">
              <Skeleton height="100%" width="100%" />
            </div>
            <div className="relative h-[200px] md:h-[600px] xl:w-[60%]">
              <Skeleton height="100%" />
            </div>
            <div className="flex justify-center items-center flex-col lg:flex-row xl:flex-col xl:w-[30%] gap-1">
              <div className="bg-slate-300 w-[300px] h-[300px]">
                <Skeleton height="100%" width="100%" />
              </div>
              <div className="hidden md:block">
                <div className="grid gap-5  grid-cols-2">
                  {Array.from({ length: 4 }).map((_, index) => (
                    <div key={index} className="md:h-36 h-20 ">
                      <Skeleton height="100%" width={200} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="grid gap-5 mt-6 grid-cols-3 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6">
            {Array.from({ length: 12 }).map((_, index) => (
              <div key={index} className="md:h-36 h-20 overflow-hidden">
                <Skeleton height="100%" width={250} />
              </div>
            ))}
          </div>
          <div className="flex mt-5 bg-white p-4 rounded-lg flex-col lg:flex-row lg:justify-between mb-6">
            <section className="flex-1 lg:mr-4">
              <h2 className="text-2xl font-semibold mb-2">
                <Skeleton width={200} />
              </h2>
              <Skeleton count={5} />
              <h2 className="text-2xl font-semibold mb-2">
                <Skeleton width={200} />
              </h2>
              <Skeleton count={5} />
            </section>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SkeletonLoader;

import React, { useState } from "react";
import { useCreateContactMutation } from "../services/apiSlice"; // Adjust the path as necessary
import OnClickAds from "../components/Ads/OnClickAds";

const ContactUs = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const [createContact, { isLoading }] = useCreateContactMutation(); // RTK mutation

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      await createContact({
        name,
        email,
        subject,
        note: message,
      }).unwrap();
      setSuccess("Your message has been sent successfully!");
      setName("");
      setEmail("");
      setSubject("");
      setMessage("");
    } catch (error) {
      setError("Failed to send your message. Please try again.");
    }
  };

  return (
    <div className=" min-h-screen flex flex-col">
      <OnClickAds />
      <main className="flex-grow flex items-center justify-center p-4">
        <section className="bg-white rounded-lg shadow-md p-6 mb-6 max-w-6xl w-full">
          <h1 className="text-3xl font-bold mb-4 text-center">Contact Us</h1>
          <p className="mb-4 text-center">
            We love hearing from our users! Whether you have questions,
            feedback, or just want to say hello, there are several ways to
            connect with us.
          </p>
          <p className="mb-4 text-center">Mobile No: +917069698484</p>
          <p className="mb-4 text-center">
            Address: 577, Gadhpur Township, Kathodara to Pasodara Road, Kamrej,
            Surat 394326 Gujarat, India
          </p>

          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label
                htmlFor="name"
                className="block text-sm font-semibold mb-1"
              >
                Name (optional)
              </label>
              <input
                type="text"
                id="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="email"
                className="block text-sm font-semibold mb-1"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="subject"
                className="block text-sm font-semibold mb-1"
              >
                Subject (optional)
              </label>
              <input
                type="text"
                id="subject"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="w-full px-3 py-2 border rounded"
              />
            </div>
            <div className="mb-4">
              <label
                htmlFor="message"
                className="block text-sm font-semibold mb-1"
              >
                Message
              </label>
              <textarea
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full px-3 py-2 border rounded"
                required
              />
            </div>
            {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
            {success && (
              <p className="text-green-500 mb-4 text-center">{success}</p>
            )}
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded "
              disabled={isLoading}
            >
              {isLoading ? "Sending..." : "Send Message"}
            </button>
          </form>
        </section>
      </main>
    </div>
  );
};

export default ContactUs;

import React, { useEffect, useState } from "react";
import GameCard from "./GameCard"; // Ensure this component exists and works
import { useLazyGetGamesByTitleQuery } from "../services/apiSlice";

const SearchModal = ({
  isOpen,
  onClose,
  searchQuery,
  setSearchQuery,
  isLoading,
  isSuccess,
}) => {
  const [searchResults, setSearchResults] = useState([]);

  // Use the query hook to fetch games based on titles
  const [
    getGames,
    {
      isSuccess: isGameList,
      isLoading: isGameLoading,
      data: favoriteGamesData,
    },
  ] = useLazyGetGamesByTitleQuery();
  useEffect(() => {
    if (searchQuery) {
      getGames({ title: searchQuery.trim() }); // Trigger the API call when searchQuery changes
    }
  }, [searchQuery, getGames]);
  useEffect(() => {
    if (isGameList && !isGameLoading) {
      setSearchResults(favoriteGamesData?.data);
    }
  }, [isGameList, isGameLoading, favoriteGamesData]);

  if (!isOpen) return null;

  const noGamesFound =
    searchResults.length === 0 && !isLoading && isSuccess && searchQuery;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div
        className="fixed inset-0 bg-gray-900 opacity-60"
        onClick={onClose}
      ></div>
      <div className="relative bg-gradient-to-br from-blue-50 via-white to-gray-200 p-8 rounded-lg shadow-lg z-10 w-full max-w-5xl overflow-hidden">
        <button
          className="absolute top-4 right-4 text-red-600 hover:text-red-800 transition duration-150 ease-in-out"
          onClick={onClose}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 384 512"
            className="h-6 w-6"
          >
            <path d="M376.6 84.5c11.3-13.6 9.5-33.8-4.1-45.1s-33.8-9.5-45.1 4.1L192 206 56.6 43.5C45.3 29.9 25.1 28.1 11.5 39.4S-3.9 70.9 7.4 84.5L150.3 256 7.4 427.5c-11.3 13.6-9.5 33.8 4.1 45.1s33.8 9.5 45.1-4.1L192 306 327.4 468.5c11.3 13.6 31.5 15.4 45.1 4.1s15.4-31.5 4.1-45.1L233.7 256 376.6 84.5z" />
          </svg>
        </button>
        <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
          Search Results
        </h2>
        <div className="mb-6">
          <input
            type="text"
            className="w-full p-4 border border-gray-300 rounded-full shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out"
            placeholder="Search games by title..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
        {noGamesFound ? (
          <div className="text-center py-6">
            <div className="bg-yellow-100 border border-yellow-300 text-yellow-800 px-6 py-4 rounded-lg shadow-md">
              <p className="text-lg font-semibold">No Games Found</p>
              <p className="text-yellow-700 mt-2">
                We couldn't find any games matching your search. Please try
                again with a different keyword.
              </p>
            </div>
          </div>
        ) : (
          <div className="h-[60vh] overflow-y-auto" style={{ height: "60vh" }}>
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 p-4">
              {searchResults.length > 0 ? (
                searchResults.map((game) => (
                  <GameCard
                    key={game.id}
                    game={game}
                    className="shadow-lg hover:shadow-xl transition-shadow duration-300"
                    onClick={onClose} // Close the modal on game card click
                  />
                ))
              ) : (
                <p className="col-span-full flex items-center justify-center text-gray-500 text-center">
                  No games found
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchModal;

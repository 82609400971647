import React from "react";
import OnClickAds from "../components/Ads/OnClickAds";
const AboutUs = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <OnClickAds />
      <main className="flex-grow flex items-center justify-center p-4">
        <section className="bg-white rounded-lg shadow-md p-6 mb-6 max-w-6xl w-full">
          <h2 className="text-2xl font-semibold mb-4">Welcome to Web Gamer</h2>
          <p className="mb-4">
            Welcome to Web Gamer, your ultimate destination for all things
            gaming!
          </p>
          <p className="mb-4">
            At Web Gamer, we are passionate about gaming and dedicated to
            providing gamers of all levels with a platform to explore, connect,
            and thrive in the gaming universe. Whether you're a casual player, a
            competitive gamer, or a seasoned pro, Web Gamer is your virtual home
            where you can immerse yourself in the exciting world of gaming.
          </p>
          <p className="mb-4">
            Our mission is to create a vibrant and inclusive gaming community
            where players from all walks of life can come together to share
            their passion, discover new games, and forge lasting friendships.
            With a diverse range of gaming content and resources, we strive to
            cater to the interests and preferences of every gamer, ensuring that
            there's something for everyone at Web Gamer.
          </p>
          <p className="mb-4">
            What sets us apart is our commitment to excellence and innovation.
            We are constantly exploring new technologies, trends, and
            developments in the gaming industry to bring you the latest news,
            reviews, and insights. Our team of experienced gamers and industry
            experts works tirelessly to curate engaging and informative content
            that informs, entertains, and inspires our community.
          </p>
          <p className="mb-4">
            At Web Gamer, we believe that gaming is more than just a hobby –
            it's a lifestyle. It's about pushing boundaries, embracing
            challenges, and unlocking new levels of creativity and skill.
            Whether you're embarking on epic quests, battling against formidable
            foes, or exploring vast virtual worlds, Web Gamer is your trusted
            companion on your gaming journey.
          </p>
          <p className="mb-4">
            Join us as we embark on this thrilling adventure through the cosmos
            of gaming. Together, let's explore the infinite possibilities, forge
            unforgettable memories, and make our mark in the ever-expanding
            universe of Web Gamer. The journey awaits – are you ready to blast
            off into the stars of gaming greatness?
          </p>
          <div className="bg-gray-200 p-4 rounded-lg mt-6">
            <h3 className="text-xl font-semibold mb-2">Contact Us</h3>
            <p className="mb-2">
              For any inquiries, feel free to contact us at:
            </p>
            <p className="mb-2">Mobile No: +917069698484</p>
            <p>
              Address: 577, Gadhpur Township, Kathodara to Pasodara Road,
              Kamrej, Surat 394326 Gujarat, India
            </p>
          </div>
        </section>
      </main>
    </div>
  );
};

export default AboutUs;

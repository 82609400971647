import React, {
  startTransition,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  useLazyGetGameByIdQuery,
  useLazyGetGamesQuery,
  useLikeDislikeGameMutation,
} from "../services/apiSlice";
import { useParams } from "react-router-dom";
import SkeletonLoader from "../components/SkeletonLoader";
import GameCard from "../components/GameCard";
import { useDispatch, useSelector } from "react-redux";
import { addFavorite, removeFavorite } from "../redux/favoritesSlice";
import AdUnitResponsive from "../components/Ads/AdUnitResponsive";
import AdUnitPC from "../components/Ads/AdUnitPC";
import RightAds from "../components/Ads/RightAd";
import BottomDetailAds from "../components/Ads/DetailBottomAd";
import AdUnitMobile from "../components/Ads/AdUnitMobile";
import OnClickAds from "../components/Ads/OnClickAds";
import HomeTopAd from "../components/Ads/HomeTopAd";
import AdCard01 from "../components/Ads/AdCard01";
import AdCard02 from "../components/Ads/AdCard02";
import AdCard03 from "../components/Ads/AdCard03";

const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => func(...args), delay);
  };
};

const GameDetailPage = () => {
  const { id } = useParams();
  const favorites = useSelector((state) => state.favorites.favorites);
  const dispatch = useDispatch();
  const [
    triggerGame,
    {
      data: gameData,
      error: gameError,
      isLoading: isGameLoading,
      isSuccess: isGameSuccess,
    },
  ] = useLazyGetGameByIdQuery();
  const [triggerGameList] = useLazyGetGamesQuery();
  const [likeDislikeGame] = useLikeDislikeGameMutation();

  const [page, setPage] = useState(1);
  const [games, setGames] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [noMoreGames, setNoMoreGames] = useState(false);
  const [fetchLimit, setFetchLimit] = useState(50);
  const [gamesWithStatus, setGamesWithStatus] = useState([]);
  const [cardsPerAd, setCardsPerAd] = useState(20); // Default for large screens
  const maxAds = 5;

  // Set page title on mount
  useEffect(() => {
    document.title = "Game Details";
  }, []);

  // Fetch game details and like status on mount
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    triggerGame(id);

    const savedLikeStatus = localStorage.getItem("games-like-status");
    if (savedLikeStatus) {
      setGamesWithStatus(JSON.parse(savedLikeStatus));
    }
  }, [id, triggerGame]);

  useEffect(() => {
    const updateCardsPerAd = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setCardsPerAd(9); // Mobile and tablet
      } else {
        setCardsPerAd(20); // Desktop
      }
    };

    updateCardsPerAd();

    window.addEventListener("resize", updateCardsPerAd);

    return () => window.removeEventListener("resize", updateCardsPerAd);
  }, []);

  // Handle screen resize to adjust fetch limit
  useEffect(() => {
    const handleResize = () => {
      setFetchLimit(window.innerWidth <= 640 ? 21 : 50);
    };

    handleResize(); // Initial call
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Load more games when scrolling
  const loadMoreGames = useCallback(() => {
    const scrollPosition =
      window.innerHeight + document.documentElement.scrollTop;
    const threshold = document.documentElement.offsetHeight - 300;

    if (scrollPosition >= threshold && hasMore) {
      startTransition(() => setPage((prevPage) => prevPage + 1));
    }
  }, [hasMore]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(loadMoreGames, 200);
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, [loadMoreGames]);

  // Fetch games based on the current page
  useEffect(() => {
    const fetchGames = async () => {
      if (noMoreGames) return;

      try {
        const response = await triggerGameList({
          page,
          limit: fetchLimit,
        }).unwrap();
        const newGames = response.data?.games || [];

        if (newGames.length === 0) {
          setHasMore(false);
          setNoMoreGames(true);
        } else {
          setGames((prevGames) => {
            const filteredNewGames = newGames.filter(
              (game) =>
                !prevGames.some((existingGame) => existingGame.id === game.id)
            );
            return [...prevGames, ...filteredNewGames];
          });
        }
      } catch (err) {
        console.error("Failed to fetch games:", err);
      }
    };

    fetchGames();
  }, [page, fetchLimit, noMoreGames, triggerGameList]);

  const handleFullScreen = () => {
    const iframe = document.getElementById("game-iframe");
    if (!iframe) return;

    // Check for iOS devices and avoid full screen
    const isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    if (isIOS) {
      alert("Full screen mode is not supported on iOS devices.");
      return;
    }

    // Check for full-screen API support
    if (iframe.requestFullscreen) {
      iframe.requestFullscreen();
    } else if (iframe.mozRequestFullScreen) {
      // Firefox
      iframe.mozRequestFullScreen();
    } else if (iframe.webkitRequestFullscreen) {
      // Chrome, Safari, Opera
      iframe.webkitRequestFullscreen();
    } else if (iframe.msRequestFullscreen) {
      // IE/Edge
      iframe.msRequestFullscreen();
    } else {
      alert("Full screen mode is not supported on this device.");
    }
  };

  // Handle like/dislike of games
  const handleLikeDislike = async (title) => {
    try {
      const currentStatus = gamesWithStatus.find(
        (game) => game.title === title
      );
      const newStatus = !currentStatus?.liked;

      const response = await likeDislikeGame({
        title,
        like: newStatus,
      }).unwrap();

      const updatedGames = gamesWithStatus.filter(
        (game) => game.title !== title
      );
      if (newStatus !== null) {
        updatedGames.push({ title, liked: newStatus });
      }

      setGamesWithStatus(updatedGames);
      localStorage.setItem("games-like-status", JSON.stringify(updatedGames));
      triggerGame(id); // Fetch updated game data

      console.log(response.message);
    } catch (error) {
      console.error("Failed to update like/dislike:", error);
    }
  };

  // Update local storage whenever gamesWithStatus changes
  useEffect(() => {
    localStorage.setItem("games-like-status", JSON.stringify(gamesWithStatus));
  }, [gamesWithStatus]);

  if (isGameLoading) {
    return (
      <div className="min-h-screen">
        <SkeletonLoader />
      </div>
    );
  }

  if (gameError) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-red-100 text-red-600 p-6 rounded-lg shadow-md">
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={64}
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mx-auto mb-4"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-1 16h2v-2h-2v2zm0-4h2V7h-2v7z" />
          </svg>
          <h1 className="text-3xl font-bold mb-2">Oops!</h1>
        </div>
      </div>
    );
  }

  if (!isGameLoading && isGameSuccess) {
    const likeStatus = gamesWithStatus.find(
      (game) => game.title === gameData?.data?.title
    )?.liked;

    const isFavorite = favorites.includes(gameData.data.title);

    const adsArray = [
      <HomeTopAd />,
      <AdUnitMobile />,
      <AdCard01 />,
      <AdCard02 />,
      <AdCard03 />,
    ];

    return (
      <div className="px-6 overflow-hidden  min-h-screen py-6">
        <div className="flex items-center w-fit block md:hidden justify-center p-4 bg-white rounded-[20px] shadow-lg font-bold">
          <h4>{gameData?.data.title}</h4>
        </div>
        <main className="relative">
          <div className="flex gap-4 flex-col xl:flex-row">
            {/* Left Side Ad */}
            <div className="w-[200px] h-[500px] hidden xl:block">
              <AdUnitResponsive />
            </div>
            {/* desktop or tablet view */}
            <div className="flex-1 md:block hidden xl:w-[60%]">
              <div className="bg-white relative h-[550px] rounded">
                {/* Game iframe */}
                <iframe
                  id="game-iframe"
                  src={gameData.data.gameurl}
                  title={gameData.data.title}
                  className="w-full rounded-t "
                  style={{ height: "500px", border: "none" }}
                />
                <div className="absolute bottom-3 right-4 rounded flex items-center space-x-4 text-gray-600">
                  <div
                    className={`flex cursor-pointer items-center space-x-1 ${likeStatus ? "text-red-500" : ""
                      }`}
                    onClick={() => handleLikeDislike(gameData.data.title)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={18}
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill={likeStatus ? "red" : "currentColor"}
                        d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                      />
                    </svg>
                    <span>{gameData.data.like_count}</span>
                  </div>
                  <div
                    className="flex items-center cursor-pointer space-x-1"
                    onClick={() =>
                      isFavorite
                        ? dispatch(removeFavorite(gameData?.data?.title))
                        : dispatch(addFavorite(gameData.data.title))
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height={20}
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill={isFavorite ? "gold" : "currentColor"}
                        d="M12 2l2.9 7.1H22l-5.6 4.4L18.3 22 12 17.9 5.7 22l2.1-8.5L2.2 9.1H9.1z"
                      />
                    </svg>
                    <span>{isFavorite ? "Favorited" : "Favorite"}</span>
                  </div>
                  <div
                    className="flex items-center space-x-1"
                    onClick={handleFullScreen}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                      height={18}
                    >
                      <path d="M32 32C14.3 32 0 46.3 0 64l0 96c0 17.7 14.3 32 32 32s32-14.3 32-32l0-64 64 0c17.7 0 32-14.3 32-32s-14.3-32-32-32L32 32zM64 352c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 96c0 17.7 14.3 32 32 32l96 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-64 0 0-64zM320 32c-17.7 0-32 14.3-32 32s14.3 32 32 32l64 0 0 64c0 17.7 14.3 32 32 32s32-14.3 32-32l0-96c0-17.7-14.3-32-32-32l-96 0zM448 352c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 64-64 0c-17.7 0-32 14.3-32 32s14.3 32 32 32l96 0c17.7 0 32-14.3 32-32l0-96z" />
                    </svg>
                  </div>
                </div>
              </div>
              {/* Bottom Ad */}
              <div className="w-full h-[100px] mt-4">
                <AdUnitPC />
              </div>
            </div>
            {/* Mobile View */}
            <div className="block md:hidden mt-5 text-black text-2xl font-bold z-10">
              <a href={gameData?.data?.gameurl}>
                <div
                  key={gameData?.data.id}
                  className="relative aspect-square group rounded-[20px] overflow-hidden box-border border-4 border-transparent"
                >
                  <img
                    src={gameData?.data.logo || "/placeholder-image.png"}
                    alt={gameData?.data.title || "game"}
                    loading="lazy"
                    className="w-full h-full object-cover transition-opacity duration-300"
                  />
                  {/* Overlay Layer */}
                  <div className="absolute inset-0 bg-black opacity-20 z-10" />
                  {/* Play Now Icon */}
                  <div className="absolute inset-0 flex flex-col items-center justify-center z-20 opacity-100 transition-opacity duration-300">
                    <div className="p-3 rounded-full shadow-xl transform transition-transform duration-500 hover:scale-110 flex items-center justify-center animate-blink">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        className="w-12 h-12 text-white"
                      >
                        <path d="M8 5v14l11-7L8 5z" />
                      </svg>
                    </div>
                    <p className="text-white">Play now</p>
                  </div>
                </div>
              </a>
              <div className="rounded flex items-center space-x-2 mt-2">
                {/* Like Button */}
                <div
                  className={`flex cursor-pointer items-center space-x-2 px-4 py-2 rounded-full transition-all duration-500 ${likeStatus
                    ? "text-white bg-gradient-to-r from-red-500 to-pink-500 shadow-lg"
                    : "bg-white text-gray-600  hover:bg-gradient-to-r hover:from-red-500 hover:to-pink-500 hover:text-white shadow-md"
                    }`}
                  onClick={() => handleLikeDislike(gameData.data.title)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height={20}
                    viewBox="0 0 512 512"
                    className="transition-transform duration-500 transform-gpu group-hover:scale-125"
                  >
                    <path
                      fill={likeStatus ? "white" : "currentColor"}
                      d="M47.6 300.4L228.3 469.1c7.5 7 17.4 10.9 27.7 10.9s20.2-3.9 27.7-10.9L464.4 300.4c30.4-28.3 47.6-68 47.6-109.5v-5.8c0-69.9-50.5-129.5-119.4-141C347 36.5 300.6 51.4 268 84L256 96 244 84c-32.6-32.6-79-47.5-124.6-39.9C50.5 55.6 0 115.2 0 185.1v5.8c0 41.5 17.2 81.2 47.6 109.5z"
                    />
                  </svg>
                  <span className="font-bold text-sm">
                    {gameData.data.like_count}
                  </span>
                </div>

                {/* Favorite Button */}
                <div
                  className={`flex cursor-pointer items-center  px-4 py-2 gap-1 rounded-full transition-all duration-500 ${isFavorite
                    ? "text-white bg-gradient-to-r from-yellow-500 to-orange-500 shadow-lg"
                    : "bg-white text-gray-600 hover:text-yellow-500 hover:bg-gradient-to-r hover:from-yellow-500 hover:to-orange-500 hover:text-white shadow-md"
                    }`}
                  onClick={() =>
                    isFavorite
                      ? dispatch(removeFavorite(gameData?.data?.title))
                      : dispatch(addFavorite(gameData.data.title))
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height={22}
                    viewBox="0 0 24 24"
                    className="transition-transform duration-500 transform-gpu group-hover:scale-125"
                  >
                    <path
                      fill={isFavorite ? "white" : "currentColor"}
                      d="M12 2l2.9 7.1H22l-5.6 4.4L18.3 22 12 17.9 5.7 22l2.1-8.5L2.2 9.1H9.1z"
                    />
                  </svg>
                  <span className="font-bold text-sm">
                    {isFavorite ? "Favorited" : "Favorite"}
                  </span>
                </div>
              </div>

              <AdUnitMobile />
            </div>
            <div className="flex justify-center items-center flex-col lg:flex-row xl:flex-col xl:w-[30%] gap-1">
              <div className="right-ads w-[300px] h-auto md:block hidden">
                <RightAds />
              </div>
              <div className="hidden md:block">
                <div className="grid  grid-cols-4  md:grid-cols-3">
                  {games.slice(0, 6).map((game, index) => (
                    <div
                      onClick={() => (window.location.href = `/${game.id}`)}
                      key={game.id}
                      className="relative md:shadow-black aspect-square group rounded-[20px] overflow-hidden box-border border-4 border-transparent hover:border-white game-card"
                    >
                      <img
                        src={game.logo || "/placeholder-image.png"}
                        alt={game.title || "game"}
                        loading="lazy"
                        className="w-full h-full object-cover transition-opacity duration-300"
                      />
                      <div className="absolute inset-0 flex items-end p-1 md:p-4 bg-gradient-to-t from-[#000000c4] via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                        <div className="text-white text-xs md:text-lg font-semibold truncate">
                          {game.title}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="grid mt-2 gap-2 md:gap-4 md:grid-cols-10 grid-cols-3">
            {games.slice(7).map((game, index) => {
              const adIndex = Math.floor(index / cardsPerAd); // Determine which ad to use

              // Only show ads if adIndex is less than maxAds
              const adComponent =
                adIndex < maxAds ? adsArray[adIndex % adsArray.length] : null;

              return (
                <React.Fragment key={game.id}>
                  <GameCard game={game} className="col-span-1 row-span-1 grid-item" />
                  {(index + 1) % cardsPerAd === 0 && adComponent && (
                    <div className="col-span-3 md:col-span-10">
                      {adComponent} {/* Insert ad after every X cards */}
                    </div>
                  )}
                </React.Fragment>
              );
            })}
          </div>
          <div className="w-[300px] h-[300px] block mt-5 md:hidden">
            <AdUnitResponsive />
          </div>

          {/* Main Content Sections */}
          <div className="flex mt-5 bg-white p-4 rounded-lg flex-col lg:flex-row lg:justify-between mb-6">
            <section className="flex-1 lg:mr-4">
              <h2 className="text-2xl font-semibold mb-2">Game Description</h2>
              <div
                className="mb-6 text-gray-700"
                dangerouslySetInnerHTML={{
                  __html: gameData.data.description,
                }}
              />
              <h2 className="text-2xl font-semibold mb-2">
                Additional Information
              </h2>
              <div
                className="mb-6 text-gray-700"
                dangerouslySetInnerHTML={{ __html: gameData.data.sec_desc }}
              />
            </section>
          </div>
          <BottomDetailAds />
        </main>
      </div>
    );
  }

  if (isGameSuccess && !gameData?.data) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-100 text-gray-600 p-6 rounded-lg shadow-md">
        <div className="text-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height={64}
            viewBox="0 0 24 24"
            fill="currentColor"
            className="mx-auto mb-4"
          >
            <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2V7zm0 8h2v2h-2v-2z" />
          </svg>
          <h1 className="text-3xl font-bold mb-2">Not Found</h1>
          <p className="text-lg mb-4">
            The game data you are looking for could not be found.
          </p>
          <button
            onClick={() => window.history.back()}
            className="px-4 py-2 bg-gray-500 text-white rounded-lg hover:bg-gray-600"
          >
            Go Back
          </button>
        </div>
      </div>
    );
  }
  return (
    <div className="min-h-screen">
      <SkeletonLoader />
      <OnClickAds />
    </div>
  );
};

export default GameDetailPage;

// src/components/GameCardSkeleton.js
import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css"; // Import the CSS

const GameCardSkeleton = ({ className }) => {
  return (
    <div
      className={`relative rounded-lg shadow-lg overflow-hidden transition-transform transform hover:scale-105 hover:shadow-xl ${className}`}
    >
      <Skeleton height="100%" width="100%" />
    </div>
  );
};

export default GameCardSkeleton;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="text-black py-8 bg-gray-100">
      <div className="container mx-auto flex flex-col md:flex-row justify-between items-center min-h-[100px] px-4 md:px-0">
        {/* Footer Logo */}
        <Link to="/" className="text-2xl font-bold mb-4 md:mb-0">
          <div className="h-[5rem] w-[18rem]">
            <img
              src={require("../logo-05.png")}
              alt="logo"
              className="h-full w-full"
            />
          </div>
        </Link>

        {/* Navigation Links */}
        <nav className="flex md:flex-row flex-col  justify-center md:justify-start md:gap-5 gap-2  mb-4 md:mb-0">
          <Link
            to="/about-us"
            className="transition-colors duration-300"
          >
            About Us
          </Link>
          <Link
            to="/privacy-policy"
            className="transition-colors duration-300"
          >
            Privacy Policy
          </Link>
          <Link
            to="/terms-and-conditions"
            className="transition-colors duration-300"
          >
            Terms & Conditions
          </Link>
          <Link
            to="/contact-us"
            className="transition-colors duration-300"
          >
            Contact Us
          </Link>
        </nav>

        {/* Social Media Icons */}
        <div className="flex  space-x-4">
          <a
            href="https://www.facebook.com"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-colors duration-300"
            aria-label="Facebook"
          >
            <svg
              className="h-6 w-6"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M22.675 0h-21.35C.6 0 0 .6 0 1.337v21.326C0 23.4.6 24 1.325 24h11.5v-9.294H9.838v-3.622h2.987v-2.67c0-2.933 1.794-4.532 4.416-4.532 1.254 0 2.333.093 2.646.135v3.07l-1.816.001c-1.425 0-1.7.678-1.7 1.669v2.327h3.404l-.444 3.622h-2.96V24h5.803c.725 0 1.325-.6 1.325-1.337V1.337C24 .6 23.4 0 22.675 0z" />
            </svg>
          </a>
          <a
            href="https://www.twitter.com"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-colors duration-300"
            aria-label="Twitter"
          >
            <svg
              className="h-6 w-6"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M23.643 4.937c-.835.37-1.732.619-2.675.728.962-.576 1.7-1.49 2.048-2.578-.9.533-1.896.922-2.958 1.13-.849-.904-2.053-1.47-3.394-1.47-2.591 0-4.694 2.103-4.694 4.693 0 .367.043.725.124 1.071C7.691 8.095 4.066 6.13 1.64 3.161c-.403.69-.634 1.49-.634 2.349 0 1.62.824 3.047 2.075 3.883-.765-.025-1.484-.234-2.113-.584v.06c0 2.26 1.607 4.144 3.743 4.573-.392.106-.806.163-1.232.163-.301 0-.593-.029-.878-.085.593 1.852 2.31 3.2 4.342 3.238-1.592 1.248-3.6 1.993-5.785 1.993-.376 0-.747-.022-1.112-.065 2.062 1.323 4.515 2.095 7.15 2.095 8.577 0 13.26-7.105 13.26-13.263 0-.202-.004-.403-.012-.604.91-.658 1.7-1.48 2.323-2.417z" />
            </svg>
          </a>
          <a
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            className="transition-colors duration-300"
            aria-label="Instagram"
          >
            <svg
              className="h-6 w-6"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M12 2.163c3.204 0 3.584.012 4.849.07 1.18.056 1.912.24 2.36.401.564.197.97.435 1.397.862.427.427.665.833.862 1.397.16.448.345 1.18.401 2.36.058 1.265.07 1.645.07 4.849s-.012 3.584-.07 4.849c-.056 1.18-.24 1.912-.401 2.36-.197.564-.435.97-.862 1.397-.427.427-.833.665-1.397.862-.448.16-1.18.345-2.36.401-1.265.058-1.645.07-4.849.07s-3.584-.012-4.849-.07c-1.18-.056-1.912-.24-2.36-.401-.564-.197-.97-.435-1.397-.862-.427-.427-.665-.833-.862-1.397-.16-.448-.345-1.18-.401-2.36-.058-1.265-.07-1.645-.07-4.849s.012-3.584.07-4.849c.056-1.18.24-1.912.401-2.36.197-.564.435-.97.862-1.397.427-.427.833-.665 1.397-.862.448-.16 1.18-.345 2.36-.401 1.265-.058 1.645-.07 4.849-.07zm0-2.163C8.756 0 8.322.014 7.053.072 5.782.13 4.737.331 3.888.66 3.065.977 2.387 1.42 1.71 2.097c-.677.677-1.12 1.355-1.437 2.178-.329.849-.53 1.894-.588 3.165-.058 1.269-.072 1.703-.072 4.947s.014 3.678.072 4.947c.058 1.271.259 2.316.588 3.165.317.823.76 1.501 1.437 2.178.677.677 1.355 1.12 2.178 1.437.849.329 1.894.53 3.165.588 1.269.058 1.703.072 4.947.072s3.678-.014 4.947-.072c1.271-.058 2.316-.259 3.165-.588.823-.317 1.501-.76 2.178-1.437.677-.677 1.12-1.355 1.437-2.178.329-.849.53-1.894.588-3.165.058-1.269.072-1.703.072-4.947s-.014-3.678-.072-4.947c-.058-1.271-.259-2.316-.588-3.165-.317-.823-.76-1.501-1.437-2.178-.677-.677-1.355-1.12-2.178-1.437-.849-.329-1.894-.53-3.165-.588-1.269-.058-1.703-.072-4.947-.072zm0 5.838c-3.403 0-6.163 2.76-6.163 6.163s2.76 6.163 6.163 6.163 6.163-2.76 6.163-6.163-2.76-6.163-6.163-6.163zm0 10.163c-2.208 0-4-1.792-4-4s1.792-4 4-4 4 1.792 4 4-1.792 4-4 4zm6.406-11.845c-.796 0-1.441-.645-1.441-1.441s.645-1.441 1.441-1.441 1.441.645 1.441 1.441-.645 1.441-1.441 1.441z" />
            </svg>
          </a>
        </div>
      </div>

      <div className="container mx-auto text-center mt-4">
        <p className="text-sm">
          &copy; {new Date().getFullYear()} Web-gamer. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

const GameCard = React.memo(
  ({ game, effect, className, onClick = () => {} }) => {
    const isLarge = className?.includes("col-span-2"); // Determine if the card is large based on the className
    const textSizeClass = isLarge ? "text-lg" : "text-sm"; // Large text for large cards, small text for small cards
    return (
      <div
        onClick={onClick}
        key={game?.id}
        className={`${className}  relative cursor-pointer shadow-md shadow-black group rounded-[20px] overflow-hidden box-border transition-transform duration-500 transform-gpu hover:scale-[1.03] hover:shadow-lg game-card`}
        style={{
          perspective: "1000px",
        }}
      >
        <div
          className="relative block h-full w-full image-container"
          onClick={() => (window.location.href = `/${game.id}`)}
        >
          {/* Shimmer effect overlay */}
          {effect && <div className="shimmer"></div>}
          <img
            src={game.logo || "/placeholder-image.png"}
            alt={`${game?.title?.split(" ")[0]} game`}
            loading="lazy"
            className="w-full h-full z-0 object-cover transition-transform duration-500 ease-out rounded-[16px]"
            style={{
              transform: "translateZ(0) scale(1)",
            }}
          />

          <div className="absolute inset-0 flex items-end p-2 md:p-4 bg-gradient-to-t from-[#000000c4] via-transparent to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 z-20">
            <div
              className={`${textSizeClass} text-white  font-semibold truncate`}
            >
              {game.title}
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default GameCard;

// src/pages/HomePage.js
import React from "react";
import GameList from "../components/GameList";
import OnClickAds from "../components/Ads/OnClickAds";

const HomePage = () => {
  return (
    <div>
      <GameList />
      <OnClickAds />
    </div>
  );
};

export default HomePage;

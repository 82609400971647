import React from "react";
import OnClickAds from "../components/Ads/OnClickAds";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <OnClickAds />
      <main className="flex-grow flex items-center justify-center p-4">
        <section className="bg-white rounded-lg shadow-md p-6 mb-6 max-w-6xl w-full">
          <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

          <h2 className="text-2xl font-semibold mb-2">1. Introduction</h2>
          <p className="mb-4">
            Welcome to Web Gamer ("we," "us," or "our"). This Privacy Policy outlines how we collect, use, disclose, and protect your information when you access or use our website and services. By using Web Gamer, you agree to the terms outlined in this Privacy Policy.
          </p>

          <h2 className="text-2xl font-semibold mb-2">2. Information We Collect</h2>
          <p className="font-semibold mb-2">a. Personal Information:</p>
          <p className="mb-4">
            When you create an account or use our services, we may collect personal information such as your name, email address, date of birth, and other information voluntarily provided.
          </p>
          <p className="font-semibold mb-2">b. Usage Data:</p>
          <p className="mb-4">
            We may collect information about your interaction with the website, including your IP address, browser type, pages visited, and time spent on the site.
          </p>
          <p className="font-semibold mb-2">c. Cookies:</p>
          <p className="mb-4">
            We use cookies and similar tracking technologies to enhance your user experience and collect data about how you use our website.
          </p>

          <h2 className="text-2xl font-semibold mb-2">3. How We Use Your Information</h2>
          <p className="font-semibold mb-2">a. Provide and Improve Services:</p>
          <p className="mb-4">
            We use your information to provide and enhance our services, troubleshoot issues, and personalize your experience on the website.
          </p>
          <p className="font-semibold mb-2">b. Communication:</p>
          <p className="mb-4">
            We may use your email address to send updates, newsletters, and promotional materials related to Web Gamer.
          </p>
          <p className="font-semibold mb-2">c. Analytics:</p>
          <p className="mb-4">
            We use analytics tools to analyze user behavior and improve our website and services.
          </p>

          <h2 className="text-2xl font-semibold mb-2">4. Information Sharing and Disclosure</h2>
          <p className="font-semibold mb-2">a. Third-Party Service Providers:</p>
          <p className="mb-4">
            We may share your information with third-party service providers that help us operate and improve our services.
          </p>
          <p className="font-semibold mb-2">b. Legal Compliance:</p>
          <p className="mb-4">
            We may disclose your information in response to legal requests, court orders, or to comply with applicable laws.
          </p>

          <h2 className="text-2xl font-semibold mb-2">5. Data Security</h2>
          <p className="mb-4">
            We implement industry-standard security measures to protect your information. However, no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee absolute security.
          </p>

          <h2 className="text-2xl font-semibold mb-2">6. Your Choices</h2>
          <p className="font-semibold mb-2">a. Account Information:</p>
          <p className="mb-4">
            You can update or delete your account information by accessing your account settings.
          </p>
          <p className="font-semibold mb-2">b. Cookies:</p>
          <p className="mb-4">
            Most browsers allow you to control cookies through their settings. However, disabling cookies may affect your experience on the website.
          </p>

          <h2 className="text-2xl font-semibold mb-2">7. Children's Privacy</h2>
          <p className="mb-4">
            Our services are not intended for users under the age of 18. We do not knowingly collect personal information from children. If you believe a child has provided us with information, please contact us, and we will promptly delete it.
          </p>

          <h2 className="text-2xl font-semibold mb-2">8. Changes to this Privacy Policy</h2>
          <p className="mb-4">
            We may update this Privacy Policy periodically. The date of the latest revision will be indicated at the top of the page. Please review this Privacy Policy regularly for any changes.
          </p>

          <h2 className="text-2xl font-semibold mb-2">9. Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns about our Privacy Policy, please contact us at <a href="mailto:gaminggalaxys.tech@gmail.com" className="text-blue-500">gaminggalaxys.tech@gmail.com</a>.
          </p>
        </section>
      </main>
    </div>
  );
};

export default PrivacyPolicy;

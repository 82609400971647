import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  favorites: [],
};

const favoritesSlice = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    loadFavorites: (state) => {
      const savedFavorites = localStorage.getItem('favorite-games');
      if (savedFavorites) {
        state.favorites = JSON.parse(savedFavorites);
      }
    },
    addFavorite: (state, action) => {
      const title = action.payload;
      if (!state.favorites.includes(title)) {
        state.favorites.push(title);
        localStorage.setItem('favorite-games', JSON.stringify(state.favorites));
      }
    },
    removeFavorite: (state, action) => {
      const title = action.payload;
      state.favorites = state.favorites.filter((fav) => fav !== title);
      localStorage.setItem('favorite-games', JSON.stringify(state.favorites));
    },
  },
});

export const { loadFavorites, addFavorite, removeFavorite } = favoritesSlice.actions;

export default favoritesSlice.reducer;

import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: 'https://api.web-gamer.com/api' }),
  endpoints: (builder) => ({
    getGames: builder.query({
      query: ({ page = 1, limit = 30 } = {}) => `games?page=${page}&limit=${limit}`,
    }),
    getGameById: builder.query({
      query: (id) => `games/${id}`, // Use the game ID to fetch a single game
    }),
    createContact: builder.mutation({
      query: (contactData) => ({
        url: 'contactus',
        method: 'POST',
        body: contactData,
      }),
    }),
    likeDislikeGame: builder.mutation({
      query: ({ title, like }) => ({
        url: `/likes/${title}`,  // Assuming the endpoint is structured like this
        method: 'POST',
        body: { like }, // Pass the like or dislike in the body
      }),
    }),
    getGamesByTitles: builder.query({
      query: ({ titles }) => ({
        url: 'games/by-titles', // Replace with your actual endpoint
        method: 'POST',
        body: { titles }, // Send the array of titles in the request body
      }),
    }),
    getGamesByTitle: builder.query({
      query: ({ title }) => ({
        url: 'games/by-title', // Replace with your actual endpoint
        method: 'POST',
        body: { title }, // Send the array of titles in the request body
      }),
    }),
  }),
});

export const {
  useLazyGetGamesQuery,
  useLazyGetGameByIdQuery,
  useCreateContactMutation,
  useLikeDislikeGameMutation,
  useLazyGetGamesByTitlesQuery,  
  useLazyGetGamesByTitleQuery,
} = apiSlice;

import React from "react";
import OnClickAds from "../components/Ads/OnClickAds";

const TermsAndConditions = () => {
  return (
    <div className=" min-h-screen flex flex-col">
      <OnClickAds />
      <main className="flex-grow flex items-center justify-center p-4">
        <section className="bg-white rounded-lg shadow-md p-6 mb-6 max-w-6xl w-full">
          <h1 className="text-3xl font-bold mb-4">Terms and Conditions of Use</h1>
          <p className="mb-4">
            Welcome to Web Gamer!
          </p>
          <p className="mb-4">
            These Terms and Conditions govern your use of the Web Gamer website (the "Website") and all content, products, and services provided by Web Gamer (collectively referred to as the "Service"). By accessing or using the Service, you agree to be bound by these Terms and Conditions. If you disagree with any part of these terms, then you may not access the Service.
          </p>
          
          <h2 className="text-2xl font-semibold mb-2">1. Intellectual Property</h2>
          <p className="mb-4">
            The Service and its original content, features, and functionality are owned by Web Gamer and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
          </p>
          
          <h2 className="text-2xl font-semibold mb-2">2. User Accounts</h2>
          <p className="mb-4">
            To access certain features of the Service, you may be required to create an account. You agree to provide accurate, current, and complete information when creating or updating your account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer or device. You agree to accept responsibility for all activities that occur under your account or password.
          </p>
          
          <h2 className="text-2xl font-semibold mb-2">3. User Content</h2>
          <p className="mb-4">
            You retain ownership of any content you submit, post, or display on or through the Service ("User Content"). However, by submitting, posting, or displaying User Content, you grant Web Gamer a non-exclusive, royalty-free, worldwide, irrevocable, and sub-licensable license to use, reproduce, adapt, distribute, publish, publicly perform, publicly display, and otherwise exploit such User Content.
          </p>
          
          <h2 className="text-2xl font-semibold mb-2">4. Prohibited Conduct</h2>
          <p className="mb-4">
            You agree not to engage in any of the following prohibited activities:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Use the Service for any illegal purpose or in violation of any local, state, national, or international law.</li>
            <li>Violate, or encourage others to violate, any right of a third party, including by infringing or misappropriating any third-party intellectual property right.</li>
            <li>Interfere with security-related features of the Service, including by disabling or circumventing features that prevent or limit use or copying of any content.</li>
            <li>Modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of the Service.</li>
          </ul>
          
          <h2 className="text-2xl font-semibold mb-2">5. Limitation of Liability</h2>
          <p className="mb-4">
            In no event shall Web Gamer, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
          </p>
          <ul className="list-disc list-inside mb-4">
            <li>Your access to or use of or inability to access or use the Service.</li>
            <li>Any conduct or content of any third party on the Service.</li>
            <li>Any content obtained from the Service.</li>
          </ul>
          
          <h2 className="text-2xl font-semibold mb-2">6. Governing Law</h2>
          <p className="mb-4">
            These Terms shall be governed and construed in accordance with the laws of India, without regard to its conflict of law provisions.
          </p>
          
          <h2 className="text-2xl font-semibold mb-2">7. Changes to Terms</h2>
          <p className="mb-4">
            Web Gamer reserves the right, at its sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
          </p>
          
          <h2 className="text-2xl font-semibold mb-2">8. Contact Us</h2>
          <p className="mb-4">
            If you have any questions or concerns regarding these Terms and Conditions, please contact us at <a href="mailto:gaminggalaxys.tech@gmail.com" className="text-blue-500">gaminggalaxys.tech@gmail.com</a>.
          </p>
        </section>
      </main>
    </div>
  );
};

export default TermsAndConditions;

import React from "react";
import GameCard from "./GameCard";

export default function FavoriteGames({
  favoriteGames,
  toggleFavoritesSidebar,
  isFavoritesSidebarOpen,
}) {
  // Handler to close the sidebar
  const handleGameCardClick = () => {
    toggleFavoritesSidebar();
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full w-96 bg-gradient-to-r from-blue-500 to-teal-500 text-white transform ${
        isFavoritesSidebarOpen ? "translate-x-0" : "translate-x-full"
      } transition-transform duration-300 ease-in-out z-50`}
    >
      <div className="flex justify-between items-center p-4">
        <div className="flex items-center space-x-2">
          <h2 className="text-2xl font-bold">Favorite Games</h2>
          <span className="bg-white text-blue-500 text-sm font-semibold px-2 py-1 rounded-full">
            {favoriteGames.length}
          </span>
        </div>
        <button
          onClick={() => toggleFavoritesSidebar()} // Close sidebar
          className="text-white focus:outline-none"
        >
          <svg
            className="h-8 w-8"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12" // Close icon
            />
          </svg>
        </button>
      </div>
      {/* Scrollable content */}
      <div className="h-[calc(100%-4rem)] overflow-x-auto">
        <div className="p-4 grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 gap-4 overflow-y-auto">
          {favoriteGames.length === 0 ? (
            <p className="col-span-full text-center px-4 py-2">
              No favorite games added yet.
            </p>
          ) : (
            favoriteGames.map((game) => (
              <GameCard
                key={game.id} // Add a key to avoid React warnings
                game={game}
                onClick={handleGameCardClick} // Pass the handler function
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
}
